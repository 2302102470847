{
  "name": "ipp-platform",
  "version": "4.7.0",
  "scripts": {
    "prepare": "cd .. && husky install",
    "start-page": "npx nx serve start-page",
    "administration": "npx nx serve administration",
    "assets": "npx nx serve asset-management",
    "auto-order-translations": "node ../scripts/auto-order-translations.js"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.0",
    "@angular/cdk": "18.2.0",
    "@angular/common": "18.2.0",
    "@angular/compiler": "18.2.0",
    "@angular/core": "18.2.0",
    "@angular/forms": "18.2.0",
    "@angular/platform-browser": "18.2.0",
    "@angular/platform-browser-dynamic": "18.2.0",
    "@angular/router": "18.2.0",
    "@microsoft/signalr": "^7.0.11",
    "@ngx-translate/core": "^15.0.0",
    "@siemens/ix-angular": "^2.5.0",
    "@siemens/ix-brand-theme": "^2.2.0",
    "@siemens/ix-echarts": "^2.3.0",
    "@siemens/ix-icons": "^2.2.0",
    "angular-auth-oidc-client": "^16.0.0",
    "autolinker": "^4.0.0",
    "autoprefixer": "10.4.5",
    "aws-rum-web": "^1.16.1",
    "caniuse-lite": "^1.0.30001358",
    "classlist.js": "^1.1.20150312",
    "echarts": "^5.4.3",
    "file-saver": "^2.0.5",
    "marked": "^12.0.2",
    "moment": "^2.29.4",
    "new-event-polyfill": "^1.0.1",
    "ng-http-loader": "^15.0.0",
    "ngx-markdown": "^18.1.0",
    "ngx-moment": "^6.0.2",
    "ngx-translate-multi-http-loader": "^18.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "ua-parser-js": "^0.7.33",
    "url-search-params-polyfill": "^8.1.0",
    "uuid": "^9.0.0",
    "web-animations-js": "^2.3.2",
    "webdav": "^4.11.3",
    "xml2js": "^0.5.0",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.0",
    "@angular-devkit/core": "18.2.0",
    "@angular-devkit/schematics": "18.2.0",
    "@angular-eslint/builder": "18.3.0",
    "@angular-eslint/eslint-plugin": "18.3.0",
    "@angular-eslint/eslint-plugin-template": "18.3.0",
    "@angular-eslint/schematics": "18.3.0",
    "@angular-eslint/template-parser": "18.3.0",
    "@angular/cli": "~18.2.0",
    "@angular/compiler-cli": "18.2.0",
    "@angular/language-service": "18.2.0",
    "@compodoc/compodoc": "^1.1.19",
    "@nx/angular": "19.6.1",
    "@nx/eslint": "19.6.1",
    "@nx/eslint-plugin": "19.6.1",
    "@nx/jest": "19.6.1",
    "@nx/js": "19.6.1",
    "@nx/workspace": "19.6.1",
    "@schematics/angular": "18.2.0",
    "@swc-node/register": "1.9.2",
    "@swc/core": "1.5.29",
    "@types/jest": "29.5.12",
    "@types/jquery": "^3.5.6",
    "@types/jwt-decode": "^3.1.0",
    "@types/marked": "^4.0.8",
    "@types/node": "^18.16.9",
    "@types/uuid": "^9.0.1",
    "@types/xml2js": "^0.4.11",
    "@typescript-eslint/eslint-plugin": "7.16.0",
    "@typescript-eslint/parser": "7.16.0",
    "eslint": "8.57.0",
    "eslint-config-prettier": "9.0.0",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-jsdoc": "^43.1.1",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^4.2.1",
    "husky": "^8.0.3",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "14.1.1",
    "jsonc-eslint-parser": "^2.1.0",
    "lint-staged": "^15.2.7",
    "mocha-junit-reporter": "^2.0.2",
    "mochawesome": "^7.1.3",
    "ng-packagr": "18.2.0",
    "nx": "19.6.1",
    "postcss": "^8.4.5",
    "postcss-import": "~14.1.0",
    "postcss-preset-env": "~7.5.0",
    "postcss-url": "~10.1.3",
    "prettier": "^2.8.8",
    "sass": "^1.26.9",
    "ts-jest": "29.1.1",
    "ts-node": "^10.9.1",
    "typescript": "5.5.4"
  },
  "lint-staged": {
    "*.js": "eslint --cache --fix"
  },
  "overrides": {
    "http-proxy-middleware@<3.0.3": "3.0.3"
  }
}
